// Variables
@import "variables";
@import "bootstrap-table";

$num-of-classes: 20;
$directions: ('top', 'bottom', 'left', 'right');
$types: ('margin', 'padding');
@mixin generate-margins {
  @each $type in $types {
    @for $i from 0 through ($num-of-classes) {
      @each $direction in $directions {
        .#{str_slice($type, 0, 1)}-#{str_slice($direction, 0, 1)}-#{$i * 5} {
          #{$type}-#{$direction}: #{$i * 5}px;
        }
        .#{str_slice($type, 0, 1)}-#{str_slice($direction, 0, 1)}-#{$i * 5}-i {
          #{$type}-#{$direction}: #{$i * 5}px !important;
        }
      }
      .#{str_slice($type, 0, 1)}-#{$i * 5} {
        #{$type}: #{$i * 5}px;
      }
      .#{str_slice($type, 0, 1)}-#{$i * 5}-i {
        #{$type}: #{$i * 5}px !important;
      }
    }
  }
}

@include generate-margins();

h3.m-subheader__title {
  .fa {
    font-size: 1.1em;
  }
}

.m-login__logo {
  h1 {
    font-size: 60pt;
    color: rgba(202, 239, 79, 1);
  }
}

.min-width-100-px {
  min-width: 100px;
}

.min-width-250-px {
  min-width: 250px;
}

.img-full-width {
  width: 100%;
}

.teacher-photo {

}

.teacher-photo-list {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #5867dd;
}

.no-wrap {
  white-space: nowrap;
}